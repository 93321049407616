.nav-item__numbering-cell {
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav-item__number {
    display: flex;
    
    border-radius: 100px;
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
    font-size: 1.25rem;
    align-items: center;
    justify-content: center;
    background-color: var(--bs-gray-400);
    color: var(--bs-gray-700);
}

.nav-item__number--is-active {
    background: #375a7f;
    color: var(--bs-body-color);
}

.nav-item__title-cell {
    display: flex;
    align-items: center;
}

.nav-item__title {
    margin: 0;
}

.nav-item__spacing-cell {
    display: flex;
    justify-content: center;
}

.nav-item__spacing-line {
    background: grey;
    width: 1px;
    height: 100%;
}