.strip-card {
    cursor: pointer;
    height: 100%;
}

.strip-card--disabled {
    cursor: initial;
    opacity: 0.3;
}

.strip-card:hover {
    border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity));
}

.strip-card--disabled:hover {
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
