.visualisation {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 16.66666%;
}

.visualisation-canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
